/*
  Authors : WeAiSys (Mozib Khan)
  Website : https://weaisys.com/
  App Name : Medical Plus
  Created : 9 May 2021
  This App Template Source code is licensed as per the
  terms found in the Website https://weaisys.com/license
  Copyright and Good Faith Purchasers © 2022-present WeAiSys Helps.
*/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://gtailor.weaisys.com/index.php/',
  mediaURL: 'https://gtailor.weaisys.com/uploads/',
  onesignal: {
    //appId: 'mzzk-pos',
    appId: '19c0ac6c-0f38-4565-b9a0-378bf36e479f',
    googleProjectNumber: '79085980607',
    restKey: 'AIzaSyBoqhE5SZ9aeqOR9eFa1_HxlKL5eGNRmbI'
  },
  general: {
    symbol: '$',
    code: 'USD'
  },
  authToken: '123456789'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
